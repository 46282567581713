import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import {
  CampaignDataService,
  SocialAuthService,
  TokenService,
  SecretPathService,
} from '@user/core/services';
import { CampaignTypeEnum, ICampaign } from '@user/core/interfaces';
import { StorageService } from '@user/core/services/storage.service';
import { UrlService } from '@user/core/services/url.service';
import {
  SocialRedirectUrlEnum,
  UserAuthProviderTypeEnum,
} from '@user/core/enums';
import { map } from 'rxjs/operators';
import {
  checkMobile,
  checkSafariPrivateMode,
  defaultBrowserExcludeSafari,
} from '@user/shared';

@Injectable({
  providedIn: 'root',
})
export class ExpiredCampaignPublicGuard implements CanActivate {
  campaign: ICampaign | null = null;

  constructor(
    @Inject('env') private env: any,
    private campaignDataService: CampaignDataService,
    private router: Router,
    private storageService: StorageService,
    private urlService: UrlService,
    private socialAuthService: SocialAuthService,
    private tokenService: TokenService,
    private secretPathService: SecretPathService
  ) {}

  redirectAndReturnFalse(urls = ['static', 'not-found']): Observable<boolean> {
    this.router.navigate(urls);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.campaignDataService.campaignInitialized.pipe(
      first(),
      mergeMap((campaign) => {
        if (!campaign) {
          return this.redirectAndReturnFalse();
        }
        this.campaign = campaign;
        return this.checkPublicDate(campaign, state);
      })
    );
  }

  private checkPublicDate(
    campaign: ICampaign,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!campaign.public_start_comparison || !campaign.public_end_comparison) {
      return this.redirectAndReturnFalse(['static', 'closed']);
    }

    if (
      this.secretPathService.isSecretPath() ||
      state.url.includes(this.secretPathService.secretPath)
    ) {
      return of(true);
    }

    // if (
    //   campaign.settings.common.is_teaser &&
    //   campaign?.settings.common.teaser_status
    // ) {
    //   if (this.checkBadRequest(campaign)) {
    //     return this.redirectAndReturnFalse(['static', 'bad-request']);
    //   }
    //   const queryParameters = this.urlService.getQueryParameters();
    //   const hashParameters = this.urlService.getHashParameters();

    //   window.location.assign('/teaser' + queryParameters + hashParameters);
    //   return of(false);
    // }

    if (
      campaign.settings.common.is_end &&
      campaign?.settings.common.end_page_status
    ) {
      if (this.checkBadRequest(campaign)) {
        return this.redirectAndReturnFalse(['static', 'bad-request']);
      }
      return this.redirectAndReturnFalse(['end-page']);
    }

    if (
      campaign.public_end_comparison.greater_than_now && // now < publicEnd
      campaign.open_end_comparison.greater_than_now && // now <= openEnd
      !campaign.reached_submission_form_limit
    ) {
      return this.checkAccessDomain(campaign, state);
    } else if (campaign.public_end_comparison.less_than_now) {
      return this.redirectAndReturnFalse();
    } else if (campaign.reached_submission_form_limit) {
      if (
        (campaign.campaign_type === CampaignTypeEnum.LOTTERY ||
          campaign.campaign_type === CampaignTypeEnum.NORMAL) &&
        !campaign.open_end_comparison.less_than_now
      ) {
        if (campaign.public_start_comparison.greater_than_now) {
          return this.redirectAndReturnFalse();
        }
        return this.checkAccessDomain(campaign, state);
      }
      return this.redirectAndReturnFalse(['static', 'closed']);
    }
    return this.redirectAndReturnFalse(['static', 'not-found']);
  }

  checkAccessDomain(
    campaign: ICampaign,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // check checkAccessDomain when Campaign is Root or not in Grouplink

    if (localStorage.getItem('check_access_domain') && checkMobile()) {
      sessionStorage.setItem('check_access_domain', 'pass');
      localStorage.removeItem('check_access_domain');
      return of(true);
    }

    if (campaign.access_domain_control_using) {
      if (!sessionStorage.getItem('check_access_domain')) {
        campaign.access_source_domain_control =
          campaign.access_source_domain_control + ',' + this.env?.accessDomain;
        let accessDomain = campaign.access_source_domain_control.split(',');
        const loginSocialDomain = [
          'access.line.me',
          'twitter.com',
          'asp.ostiaries.jp',
          'accounts.google.com',
        ];
        // accessDomain.push(campaign.parent_campaign)
        if (
          document.referrer &&
          !loginSocialDomain.find((domain) =>
            document.referrer.includes(domain)
          )
        ) {
          sessionStorage.setItem('referrer', document.referrer);
        }

        const referrer = sessionStorage.getItem('referrer') || '';

        if (
          state.url.includes('auth/line/callback') &&
          (checkSafariPrivateMode() || defaultBrowserExcludeSafari())
        ) {
          sessionStorage.setItem('referrer', window.location.origin);
          sessionStorage.setItem('check_access_domain', 'pass');
          return of(true);
        }

        if (
          state.url.includes(SocialRedirectUrlEnum.email) &&
          campaign.user_auth_provider_type &&
          campaign.user_auth_provider_type.includes(
            UserAuthProviderTypeEnum.EMAIL
          )
        ) {
          const [, , , paramsToken] = state.url.split('/');
          const [token, queryString] = paramsToken
            ? paramsToken.split('?')
            : [];
          if (token) {
            return of(true);
          }
        }
        if (
          (!referrer ||
            !accessDomain.find((domain) => referrer.includes(domain))) &&
          !sessionStorage.getItem('access_token')
        ) {
          return this.redirectAndReturnFalse(['static', 'bad-request']);
        }
      }
      return of(true);
    }
    // always pass active when campaign is children (has parent campaign)
    return of(true);
  }
  checkBadRequest(campaign: ICampaign): boolean {
    let isBadRequest = false;
    if (!campaign.parent_campaign) {
      if (campaign.access_domain_control_using) {
        campaign.access_source_domain_control =
          campaign.access_source_domain_control + ',' + this.env?.accessDomain;
        let accessDomain = campaign.access_source_domain_control.split(',');
        if (document.referrer) {
          sessionStorage.setItem('referrer', document.referrer);
        }
        const referrer = sessionStorage.getItem('referrer') || '';
        if (
          !referrer ||
          !accessDomain.find((domain) => referrer.includes(domain))
        ) {
          isBadRequest = true;
        }
      }
    }
    return isBadRequest;
  }
}
