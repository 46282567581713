import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LotteryComingSoonPageComponent } from '@app-shared/static-page/lottery-coming-soon-page/lottery-coming-soon-page.component';
import { CampaignClosedPageComponent } from './campaign-closed-page/campaign-closed-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { BadRequestPageComponent } from './bad-request-page/bad-request-page.component';
import { AlreadyAppliedPageComponent } from './already-applied-page/already-applied-page.component';
import { ExpiredComponent } from './expired/expired.component';
import { ErrorLineAddFriendComponent } from '@app-shared/static-page/error-line-add-friend/error-line-add-friend.component';
import { AlreadySentGiftPageComponent } from '@app-shared/static-page/already-sent-gift-page/already-sent-gift-page.component';
import { SettingErrorPageComponent } from '@app-shared/static-page/setting-error-page/setting-error-page.component';
import { CampaignReachedApplyLimitPageComponent } from '@app-shared/static-page/campaign-reached-apply-limit-page/campaign-reached-apply-limit-page.component';
import { UserReachedApplyLimitPageComponent } from '@app-shared/static-page/user-reached-apply-limit-page/user-reached-apply-limit-page.component';
import { UserReachedDayApplyLimitPageComponent } from '@app-shared/static-page/user-reached-day-apply-limit-page/user-reached-day-apply-limit-page.component';
import { LotteryEndPageComponent } from '@app-shared/static-page/lottery-ended-page/lottery-end-page.component';
import {
  ExpiredCampaignOpenGuard,
  ExpiredCampaignPublicGuard,
} from '@user/core/guards';

const canActivateGuards = [
  ExpiredCampaignOpenGuard,
  ExpiredCampaignPublicGuard,
];

const routes: Routes = [
  {
    path: 'not-found',
    component: NotFoundPageComponent,
  },
  {
    path: 'closed',
    component: CampaignClosedPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'bad-request',
    component: BadRequestPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'already-applied',
    component: AlreadyAppliedPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'expired',
    component: ExpiredComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'error-line-add-friend',
    component: ErrorLineAddFriendComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'already-sent-gift',
    component: AlreadySentGiftPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'setting-error',
    component: SettingErrorPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'campaign-reached-the-apply-limit',
    component: CampaignReachedApplyLimitPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'user-reached-the-apply-limit',
    component: UserReachedApplyLimitPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'user-reached-the-day-apply-limit',
    component: UserReachedDayApplyLimitPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'lottery-coming-soon',
    component: LotteryComingSoonPageComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'lottery-ended',
    component: LotteryEndPageComponent,
    canActivate: canActivateGuards,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StaticPageRoutingModule {}
